import MainItem from "./MainItem";
import { useMediaQuery } from "react-responsive";

import style from "./Main.module.css";
import { Link } from "react-router-dom";

const Main = () => {
    const isMobile = useMediaQuery({
        query: "(max-width:768px)",
    });
    if (!isMobile) {
        return (
            <main className={style.main}>
                <div className={style.main__content}>
                    <MainItem
                        img="portfolio"
                        link="/portfolio"
                        title="PORTFOLIO 2023"
                    />
                    <div className={style.divider}></div>
                    <MainItem
                        img="academic"
                        link="/academic"
                        title="ACADEMIC PROJECTS"
                    />
                </div>
                <Link to="/profile">
                    <div className={style.run}>
                        <img src="/img/util/run.jpeg" alt="run" width="100px" />
                        <h2>C.V</h2>
                    </div>
                </Link>
            </main>
        );
    }
    return (
        <main className={style.mMain}>
            <MainItem
                img="portfolio"
                link="/portfolio"
                title="PORTFOLIO 2023"
            />
            <div className={style.mDivider}></div>
            <MainItem
                img="academic"
                link="/academic"
                title="ACADEMIC PROJECTS"
            />
            <div className={style.nav}>
                <Link to="/" className={style.navItem}>
                    <span>- MAIN</span>
                </Link>
                <Link to="/profile" className={style.navItem}>
                    <span>- PROFILE</span>
                </Link>
                <Link to="/portfolio" className={style.navItem}>
                    <span>- PORTFOLIO 2023</span>
                </Link>
                <Link to="/academic">
                    <span>- ACADEMIC PROJECTS</span>
                </Link>
            </div>
        </main>
    );
};

export default Main;
